<template></template>

<script setup lang="ts">
  import { FilterMatchMode } from "primevue/api";
  import { defineColumnFilter, defineColumnProps } from "../../lib/defines";

  defineExpose(
    Object.assign(
      {},
      defineColumnProps({
        sortable: true,
        showAddButton: false,
        showApplyButton: false,
        showClearButton: false,
        showFilterMatchModes: false,
      }),
      defineColumnFilter({
        matchMode: FilterMatchMode.EQUALS,
      }),
    ),
  );
</script>

<style scoped></style>
